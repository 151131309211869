@import '/styles/const.scss';

.stores {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-right: -15px;

  & > * {
    margin-right: 15px;
  }
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;

  @media screen and (max-height: 410px) {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  & svg {
    color: $TEXT;
  }
}

.h1 {
  text-align: center;
  margin-bottom: 30px;
}

.mainText {
  color: $text-gray;
  text-align: center;
  margin-bottom: 20px;
}

.button {
  display: flex;
  min-height: 40px;
  padding: 9px 18px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: $background-blue;
  color: $text-white;
  font-size: 18px;
  justify-content: center;
  margin: 0 auto 30px;
  max-width: 170px;
}

.storesContainer {
  padding: 0 15px;
}

.foreground {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: $BACKGROUND_2;
  border-radius: 20px;
  transition: background-color $default-timing-duration $default-timing-function;
  top: 100px;
  padding: 10px 15px 0px;
  max-width: max-content;
  width: 90%;

  @media screen and (max-height: 845px) {
    top: 10px;
    transform: translateX(-50%);
  }
}
