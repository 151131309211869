@import '/styles/const.scss';

.main {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $TEXT;

  & p {
    display: inline !important;
  }
}
