$background-blue: #3171f0;
$background-white: #ffffff;

$text-main: #000000;
$text-white: #ffffff;
$text-blue: #396aff;
$text-gray: #8b8b8b;
$pink-insta: #d8246b;

$border-color: #e5e5e5;
$red-color: #dc4141;

$BACKGROUND_1: var(--BACKGROUND_1);
$BACKGROUND_2: var(--BACKGROUND_2);
$BACKGROUND_3: var(--BACKGROUND_3);
$BACKGROUND_SVG: var(--BACKGROUND_SVG);
$TEXT: var(--TEXT);
$TEXT_LINK: var(--TEXT_LINK);
$TEXT_WHITE: var(--TEXT_WHITE);
$TEXT_INVERT: var(--TEXT_INVERT);
$TEXT_GRAY: var(--TEXT_GRAY);
$BORDER: var(--BORDER);
$BLUE: var(--BLUE);

$default-timing-duration: 300ms;
$default-timing-function: ease;
