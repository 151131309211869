@import '/styles/const.scss';

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: $BACKGROUND_SVG;
  transition: background-color $default-timing-duration $default-timing-function;
  mask-image: url(/background.svg);
}
