@import '/styles/const.scss';

.mainText {
  color: $text-gray;
  margin-bottom: 15px;
  margin-top: 20px;

  &.center {
    text-align: center;
  }
}

.stores {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-right: -15px;
  &.center {
    justify-content: center;
  }

  & > * {
    margin-right: 15px;
  }
}

.socials {
  display: flex;
  margin: 10px 0;
  gap: 20px;
  width: max-content;
  &.center {
    margin: 10px auto;
  }

  & svg {
    margin: 3px;
    width: 54px;
    height: 54px;
  }
}
