@import '/styles/const.scss';

.main {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  margin: 0;
  color: $TEXT;

  & p {
    display: inline !important;
  }
}
