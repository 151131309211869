@keyframes t-logo-play {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 100% 0%;
  }
}

.main {
  background-image: url('/t_logo_sprite.svg');
  display: block;
  animation: 500ms steps(30) both;
  background-repeat: no-repeat;
  background-size: cover;
  animation-name: t-logo-play;
}
